import React, { useState, useRef } from 'react';
import UploadRecording from './components/UploadRecording';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RecordAudio = () => {
  const [recordingState, setRecordingState] = useState({
    isRecording: false,
    audioUrl: '',
    audioFile: null,
    status: '',
    sharedLink: '',
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timeoutRef = useRef(null);

  const startRecording = () => {
    if (!termsAccepted) {
      toast.error("You must accept the Terms and Conditions");
      return;
    }

    console.log('Starting recording...');
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
          console.log('Stopping previous recording...');
          mediaRecorderRef.current.stop();
        }

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          console.log('Recording stopped.');
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          setRecordingState(prev => ({
            ...prev,
            audioUrl,
            audioFile: audioBlob,
            isRecording: false,
            status: 'Recording stopped.',
          }));
        };

        mediaRecorder.start();
        setRecordingState(prev => ({
          ...prev,
          isRecording: true,
          status: 'Recording...',
        }));

        console.log('Recording started...');

        timeoutRef.current = setTimeout(() => {
          console.log('30 seconds reached, stopping recording...');
          mediaRecorder.stop();
          setRecordingState(prev => ({
            ...prev,
            isRecording: false,
            status: 'Recording stopped, exceeded 30 seconds.',
          }));
        }, 30000);
      })
      .catch(error => {
        setRecordingState(prev => ({
          ...prev,
          status: `Error: ${error.message}`,
        }));
        console.error('Error:', error);
      });
  };

  const stopRecording = () => {
    console.log('Stop recording clicked...');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setRecordingState(prev => ({
        ...prev,
        isRecording: false,
        status: 'Recording stopped.',
      }));
      clearTimeout(timeoutRef.current);
    } else {
      console.log('MediaRecorder not in recording state');
      setRecordingState(prev => ({
        ...prev,
        status: 'Recording is not active.',
      }));
    }
  };

  const resetRecording = () => {
    setRecordingState({
      isRecording: false,
      audioUrl: '',
      audioFile: null,
      status: '',
      sharedLink: '',
    });
    setTermsAccepted(false);

    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
    clearTimeout(timeoutRef.current);
  };

  const disabledButtonStyle = {
    backgroundColor: '#d3d3d3',
    cursor: 'not-allowed',
    color: '#a1a1a1',
  };

  const handleUploadSuccess = (sharedLink) => {
    setRecordingState(prev => ({
      ...prev,
      sharedLink,
      status: 'Upload Successful and link generated!',
    }));
  };

  const handleUploadFailure = () => {
    setRecordingState(prev => ({
      ...prev,
      status: 'Upload Failed. Try again!',
    }));
  };

  return (
    <div style={{maxHeight:"400px", overflowY:"scroll", scrollbarWidth: "none", msOverflowStyle: "none"}}>
      <div style={{ marginBottom: "10px" }}>
        <input
          type="checkbox"
          id="termsCheckboxRecord"
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
          style={{ marginRight: "10px" }}
        />
        <label htmlFor="termsCheckboxRecord">
          I accept the <a target='_blank' href="/terms-and-condition.html">Terms and Conditions</a>
        </label>
      </div>

      <button 
        onClick={startRecording} 
        disabled={recordingState.isRecording || recordingState.audioFile !== null || !termsAccepted} 
        style={
          recordingState.isRecording || recordingState.audioFile !== null || !termsAccepted 
            ? disabledButtonStyle 
            : {}
        }
      >
        Start Recording
      </button>
      
      <button 
        onClick={stopRecording} 
        disabled={!recordingState.isRecording} 
        style={!recordingState.isRecording ? disabledButtonStyle : {}}
      >
        Stop Recording
      </button>

      <button 
        onClick={resetRecording} 
        style={recordingState.audioFile || recordingState.isRecording ? {} : disabledButtonStyle}
        disabled={!recordingState.audioFile && !recordingState.isRecording}
      >
        Reset
      </button>

      <div style={{ marginTop: "15px" }}>
        {recordingState.audioUrl && <audio controls src={recordingState.audioUrl}></audio>}
      </div>

      <div>{recordingState.status}</div>

      {recordingState.audioUrl && (
        <UploadRecording 
          audioFile={recordingState.audioFile} 
          onUploadSuccess={handleUploadSuccess} 
          onUploadFailure={handleUploadFailure} 
        />
      )}

      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar={true}
      />
    </div>
  );
};

export default RecordAudio;
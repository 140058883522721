import React, { useState, useRef } from 'react';
import TextToSpeech from './TextToSpeech';
import RecordAudio from './RecordAudio';
import './App.css';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from './components/Footer'; // Import Footer Component

function App() {
  const [activeTab, setActiveTab] = useState('speechTab');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const recaptchaRef = useRef();

  const showTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleTermsAcceptance = () => {
    setIsTermsAccepted(true);
    setShowModal(false);
  };

  return (
    <div className="app-container">
      {/* Modal for Terms and Conditions */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Terms and Conditions</h2>
            <p>
              By using this site, you agree to the following terms and conditions. 
              Data generated through the text-to-speech and audio recording features will be 
              stored and may be shared with authorized parties upon request. 
              Please read our full <a target='_blank' href="/terms-and-condition.html">Terms and Conditions</a> for more details.
            </p>
            <div>
              <input
                type="checkbox"
                id="agreeCheckbox"
                onChange={(e) => setIsTermsAccepted(e.target.checked)}
              />
              <label htmlFor="agreeCheckbox">I agree to the Terms and Conditions</label>
            </div>
            <button
              onClick={handleTermsAcceptance}
              disabled={!isTermsAccepted}
            >
              Submit
            </button>
          </div>
        </div>
      )}

      {/* Main content */}
      {!showModal && (
        <div className="main-content">
          <div className="container">
            <div className="tabs">
              <button
                className={`tab-btn ${activeTab === 'speechTab' ? 'active' : ''}`}
                onClick={() => showTab('speechTab')}
              >
                Text to Speech
              </button>
              <button
                className={`tab-btn ${activeTab === 'recordTab' ? 'active' : ''}`}
                onClick={() => showTab('recordTab')}
              >
                Record Audio
              </button>
            </div>
            
            <div className="content-area">
              {activeTab === 'speechTab' && <TextToSpeech recaptcha={captchaToken} />}
              {activeTab === 'recordTab' && <RecordAudio recaptcha={captchaToken} />}
            </div>

            <ReCAPTCHA
              sitekey="6LcMTLQqAAAAAJf5wy3Ekl0IKqqh9ARXiLpEQgVf"
              onChange={handleCaptchaChange}
              size="invisible"
              ref={recaptchaRef}
            />
          </div>

          {/* Buy Me a Coffee section */}
          <div className="coffee-container">
            <a href="https://www.buymeacoffee.com/veleston">
              <img 
                src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=veleston&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" 
                alt="Buy me a coffee"
              />
            </a>
          </div>
        </div>
      )}

      {/* Footer Section */}
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode';

const UploadRecording = ({ audioFile, onUploadSuccess, onUploadFailure }) => {
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [loading, setLoading] = useState(false);  // Track loading state
    const [error, setError] = useState('');  // Track error message

    // Function to check if the base64 string is for an MP3
    const isBase64MP3 = (base64) => base64 && base64.startsWith('data:audio/mp3;base64,');

    // Retrieve and convert the base64 MP3 from localStorage
    const getAudioFileFromStorage = () => {
        const base64Audio = localStorage.getItem('audioFile');
        if (base64Audio && isBase64MP3(base64Audio)) {
            const byteCharacters = atob(base64Audio.split(',')[1]);
            const byteArrays = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays[i] = byteCharacters.charCodeAt(i);
            }

            return new Blob([byteArrays], { type: 'audio/mp3' });
        }
        return null;
    };

    // Function to get a pre-signed URL for S3 upload
    const getPresignedUrl = async () => {
        try {
            const response = await axios.get(`https://my-worker-production.vegaecom.workers.dev/get-presigned-url`);
            return response.data.url;
        } catch (error) {
            console.error('Error fetching pre-signed URL:', error);
            setError('Failed to fetch pre-signed URL');
            return null;
        }
    };

    // Upload the recording to S3
    const uploadRecording = async () => {
        setLoading(true);
        setError('');  // Reset error state
    
        let fileToUpload = audioFile;
    
        if (!audioFile && localStorage.getItem('audioFile')) {
            fileToUpload = getAudioFileFromStorage();
        }
    
        if (!fileToUpload) {
            setLoading(false);
            setError('No recording available to upload.');
            return;
        }
    
        const presignedUrl = await getPresignedUrl();
        if (!presignedUrl) {
            setLoading(false);
            return;
        }
    
        // Upload the file to the backend (via /upload route)
        try {
            const formData = new FormData();
            formData.append('url', presignedUrl);  // Include the pre-signed URL
            formData.append('file', fileToUpload);  // Include the file
    
            const response = await axios.post(`https://my-worker-production.vegaecom.workers.dev/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            onUploadSuccess(response.data);
    
            const uploadedFileUrl = presignedUrl.split('?')[0];
            setQrCodeUrl(uploadedFileUrl);
    
            QRCode.toDataURL(uploadedFileUrl, (err, url) => {
                if (err) {
                    console.error('Error generating QR code:', err);
                    setLoading(false);
                    return;
                }
                setQrCodeImage(url);
                setLoading(false);
            });
    
        } catch (error) {
            setError('Upload Failed. Try again!');
            onUploadFailure();
            setLoading(false);
            console.error('Upload error:', error);
        }
    };
    

    useEffect(() => {
        if (localStorage.getItem('audioFile')) {
            // Handle any additional setup if necessary
        }
    }, []);

    return (
        <div>
            <button onClick={uploadRecording} disabled={!audioFile && !localStorage.getItem('audioFile')} >
                {loading ? 'Uploading...' : 'Upload Recording'}
            </button>

            {error && <div style={{ color: 'red' }}>{error}</div>}

            {qrCodeImage && (
                <div>
                    <h3>Share this Recording:</h3>
                    <img src={qrCodeImage} alt="QR Code" />
                    <br />
                    <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">
                        Click here to view the recording
                    </a>
                </div>
            )}
        </div>
    );
};

export default UploadRecording;

import React from 'react';
import './Footer.css'; // For custom styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/about-us.html" className="footer-link">About Us</a>
        <a href="/privacy-policy.html" className="footer-link">Privacy Policy</a>
        <a href="/terms-and-condition.html" className="footer-link">Terms & Conditions &nbsp; &nbsp; | </a>
        <p className="footer-text">&copy; 2025 Audibu. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import Select from 'react-select'; 
import voicesData from './voices_limited.json'; 
import axios from 'axios'; 
import UploadRecording from './components/UploadRecording'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TextToSpeech = () => {
  const defaultVoice = `${voicesData[0].name}$$$${voicesData[0].language}`;
  const [text, setText] = useState('');
  const [voice, setVoice] = useState(defaultVoice);
  const [voices] = useState(voicesData.slice(0, 5));
  const [audioUrl, setAudioUrl] = useState(''); 
  const [audioFile, setAudioFile] = useState(null);
  const [isReadyToUpload, setIsReadyToUpload] = useState(false);
  const [sharedLink, setSharedLink] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); // New state for T&C checkbox

  console.log(sharedLink);
  const options = voices.map((voice) => ({
    value: `${voice.name}$$$${voice.language}`,
    label: `${voice.label}`,
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!text.trim()) {
      toast.error("Text input is required!");
      return;
    }

    if (!termsAccepted) {
      toast.error("You must accept the Terms and Conditions");
      return;
    }

    const [selectedVoiceName, selectedLanguage] = voice.split('$$$');
  
    const options = {
      method: 'GET',
      url: 'https://text-to-speech-ai-tts-api.p.rapidapi.com/',
      params: {
        text: text,
        language: selectedLanguage,
        voice: selectedVoiceName,
      },
      headers: {
        'x-rapidapi-key': '0f4bbf891fmsh7cad98e8c6ace77p1bc0d0jsnfb63cafc15bf',
        'x-rapidapi-host': 'text-to-speech-ai-tts-api.p.rapidapi.com',
      },
    };
  
    try {
      const response = await axios.request(options);
      console.log('Speech synthesis response:', response.data);
  
      if (response.data.error === "false") {
        toast.success("Speech synthesized successfully!");
        const downloadUrl = response.data.download_url;
        console.log('Download URL:', downloadUrl);
  
        if (downloadUrl) {
          const backendUrl = `https://my-worker-production.vegaecom.workers.dev/proxy`;
          
          const audioBlobResponse = await fetch(`${backendUrl}?fileUrl=${encodeURIComponent(downloadUrl)}`);
          
          if (!audioBlobResponse.ok) {
            toast.error('Failed to fetch the audio file');
            return;
          }
  
          const audioBlob = await audioBlobResponse.blob();
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
  
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Audio = reader.result;
            localStorage.setItem('audioFile', base64Audio);
            setAudioFile(audioBlob);
            setIsReadyToUpload(true);
          };
          reader.readAsDataURL(audioBlob);
        }
      } else {
        toast.error("Failed to synthesize speech");
      }
    } catch (error) {
      console.error('Error synthesizing speech:', error);
      toast.error("Error occurred while synthesizing speech.");
    }
  };

  const handleUploadSuccess = (sharedLink) => {
    setSharedLink(sharedLink);
    toast.success("Upload successful!");
  };

  const handleUploadFailure = () => {
    toast.error("Upload failed. Try again!");
  };

  const handleReset = (e) => {
    e.preventDefault();
    setText('');
    setVoice(defaultVoice);
    setAudioUrl('');
    setAudioFile(null);
    setIsReadyToUpload(false);
    setSharedLink('');
    setTermsAccepted(false); // Also reset the terms checkbox
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#EC407A'     // Selected option background
        : state.isFocused
          ? '#F48FB1'   // Hovered option background (lighter shade)
          : 'white',    // Default background
      color: state.isSelected 
        ? 'white'       // Selected option text color
        : state.isFocused
          ? 'white'     // Hovered option text color
          : '#333',     // Default text color
      '&:active': {
        backgroundColor: '#EC407A', // Active (clicked) state
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown appears above other elements
    }),
    // Optional: Customize the control (main input) appearance
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      '&:hover': {
        borderColor: '#999',
      },
    }),
  };

  return (
    <div style={{maxHeight:"400px", overflowY:"scroll", scrollbarWidth: "none", msOverflowStyle: "none"}}>
      <form onSubmit={handleSubmit}>
        <textarea
          name="text"
          rows="4"
          cols="50"
          maxLength="200"
          placeholder="Enter text (Max 200 characters)"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <br />
        <label htmlFor="voice">Select Voice:</label><br />
        <Select
          id="voiceSelect"
          options={options}
          value={options.find(option => option.value === voice)}
          onChange={(selectedOption) => setVoice(selectedOption.value)}
          placeholder="Search voices..."
          styles={customStyles} // Apply the custom styles
          isSearchable // Enable search functionality
        />
        <br /><br />
        
        {/* Terms and Conditions checkbox */}
        <div style={{ margin: '10px 0' }}>
          <input
            type="checkbox"
            id="termsCheckbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label htmlFor="termsCheckbox">
            I accept the <a target='_blank' href="/terms-and-condition.html">Terms and Conditions</a>
          </label>
        </div>
        
        <button type="submit" disabled={!termsAccepted} style={{
          backgroundColor: termsAccepted ? "#EC407A" : "#cccccc",
          cursor: termsAccepted ? "pointer" : "not-allowed",
        }}>Generate</button>
        {audioUrl && (
          <button onClick={handleReset}>Reset</button>
        )}
      </form>

      {audioUrl && (
        <div>
          <audio controls>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
          <br />
          
          {isReadyToUpload && (
            <UploadRecording
              audioFile={audioFile}
              onUploadSuccess={handleUploadSuccess}
              onUploadFailure={handleUploadFailure}
            />
          )}
        </div>
      )}

      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar={true}
      />
    </div>
  );
};

export default TextToSpeech;